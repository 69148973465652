import * as React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GeneralLayout from '../../components/GeneralLayout';
import TitleBlock from '../../components/TitleBlock';
import Result from '../../components/Works/Result';
import CategoryMenu from '../../components/CategoryMenu';

export default function architecture() {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityProject(
        sort: {order: DESC, fields: year}
        filter: {slug: {current: {ne: "null"}}}
      ) {
        nodes {
          slug {
            current
          }
          title
          titleKr
          year
          theme {
            id
            title
            slug {
              current
            }
          }
          category {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          otherImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          id
        }
      }
    }
  `);

  const activeCategory = 'Architecture';
  const allProjects = listQuery.allSanityProject.nodes;

  function filterAllProjects(categoryName) {
    return allProjects.filter((project) => (
      project.category.some((catItem) => (catItem.title === categoryName))));
  }
  function mapToResults(projects) {
    return projects.map((project) => (
      <Result
        key={project.id}
        data={project}
      />
    ));
  }

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <GeneralLayout>
      <div className="info">
        <TitleBlock activeCategory={activeCategory} />
        {mapToResults(filterAllProjects(activeCategory))}
      </div>
      <CategoryMenu
        categoryType="Works"
        activeCategory={activeCategory}
        listQuery={listQuery}
      />
    </GeneralLayout>
  );
}
